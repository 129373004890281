import React, { useEffect, useState, useMemo } from 'react'
import { inject, observer } from 'mobx-react';
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from 'lodash';
import { menuConfig } from './layout/MenuConfig'
import moment from 'moment'
import AuthService from "./services/AuthService";

let firstRender = false, intervalRefreshToken: any = null
const Authentication = inject("authenStore")(observer((props) => {
  let authenStore = props['authenStore']
  let { getAccountAuth, setCommonValue } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const navigate = useNavigate()
  const location = useLocation()
  let { pathname } = location

  useEffect(() => {
    return
    if (firstRender) {
      let diffMinute = moment(getAccountAuth?.timestamp).diff(moment(), "minutes")

      if (isEmpty(getAccountAuth)) {

        clearInterval(intervalRefreshToken)
        if (pathname !== "/")
          navigate("/AccessDenied")
        else
          navigate("/")
      }
      else {
        clearInterval(intervalRefreshToken)
        if (diffMinute > getAccountAuth?.expire_minute) {
          navigate("/")
          setCommonValue({ accountAuth: {} })
        }
        else if (pathname === "/") navigate("/home-page")

        let expire_minute = getAccountAuth?.expire_minute
        let intervalMinutes = ((expire_minute / 4) * 3) * 1000
        intervalRefreshToken = setInterval(() => { refreshToken() }, intervalMinutes)
      }
      checkAccessPath()
    }
    else {
      firstRender = true
      // if (isEmpty(getAccountAuth)) navigate("/")
    }
  }, [getAccountAuth])

  const checkAccessPath = () => {
    if (!isEmpty(getAccountAuth) && pathname !== "/") {
      if (!isEmpty(getAccountAuth)) {
        let { menu } = getAccountAuth
        let mConfig = [...menuConfig]

        let pathActive: any = ["/home-page"]
        menu.forEach(item => {
          let { is_read, main_code, permission_code } = item
          if (is_read) {
            let mainIndex = mConfig.findIndex((item) => item.id == main_code)
            if (mainIndex >= 0) {
              let mainMenu = mConfig[mainIndex]
              if (mainMenu?.subMenu) {
                let subIndex = mainMenu.subMenu.findIndex((item) => item.id == permission_code)
                if (subIndex >= 0) {
                  mConfig[mainIndex].visible = true
                  mConfig[mainIndex].subMenu[subIndex].visible = true
                  pathActive.push(mConfig[mainIndex].subMenu[subIndex].path)
                }
              }
              else {
                mConfig[mainIndex].visible = true
                pathActive.push(mConfig[mainIndex].path)
              }
            }
          }
        })

        // if (pathname === "/mobile-log") pathActive.push("/mobile-log") // TEST
        if (!pathActive.includes(pathname)) navigate("/AccessDenied")
      }
    }
  }

  const refreshToken = () => {
    AuthService.refreshTokekn(auth, getAccountAuth?.refresh_token)
      .then((res: any) => {
        let { code, result, message, sys_message } = res.data
        if (code === 200) {
          let { access_token, expire_minute } = result
          let accountAuthCurrent = { ...getAccountAuth }
          accountAuthCurrent.access_token = access_token
          accountAuthCurrent.expire_minute = expire_minute
          setCommonValue({ accountAuth: accountAuthCurrent })
        }
        else {
          console.log(`REFRESH_TOKEN : ${message} ${sys_message}`)
        }
      })
      .catch((err) => {
        console.log(`REFRESH_TOKEN : ${err}`)
      })
  }

  return (<>
    {props.children}
  </>)
}))

export default Authentication