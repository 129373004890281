import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IReq {
  delivery_date: string
  route_no?: string
}

interface IDoc {
  route_id: number
  req_by?: string
  req_date?: string
}

interface IDelRequist {
  route_id: string
  req_by: string
  req_date: string
}


export default {
  getRequisition: async function (auth: IAuth, data: IReq) {
    let prmList: any = []
    for (const key in data) {
      if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
    }
    let param = prmList.join('&');
    if (param !== "") param = `?${param}`

    try {
      const response = await axios.get(`${BASE_URL}/requist/list${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  genDoc: async function (auth: IAuth, data: IDoc) {
    const response = await axios.put(`${BASE_URL}/requist/gendoc`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  deleteRequisition: async function (auth: IAuth, data: IDelRequist) {
    try {
      const response = await axios.delete(`${BASE_URL}/requist/delete`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        },
        data
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
}