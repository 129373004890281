
import { useState, useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react';
import { Modal, ModalAlert, FormLoading, Select, InputNumber, DatePicker } from '../../../components'
import DataGrid, { Scrolling, Column } from 'devextreme-react/data-grid';
import { CommonService, PriceModelService } from '../../../services';
import { isEmpty } from 'lodash'
import { InputNumber as InputNumberAntd } from 'antd'
import moment from 'moment'

const ModalPriceModel = inject("priceModelStore")(observer((props) => {
  let priceModelStore = props.priceModelStore
  let { setPriceModeStore, openModalPriceModel, getPriceModelData } = priceModelStore // STATE

  let { auth, masterOptions, defaultCustomer } = props // STATES
  let { onReload, onChangeProvince } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [priceSetList, setPriceSetList]: any = useState([])
  const [masterZone, setMasterZone]: any = useState([])
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [addPrice, setAddPrice] = useState(false)
  const priceSetListRef: any = useRef([]);
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [dataForm, setDataForm]: any = useState({
    "cust_id": "",
    "zone_id": "",
    "admin_level1": "",
    "admin_level2": "",
    "logistic_type_id": "",
    "product_id": "",
    "unit_id": "",
    "max_item": "",
    "min_item": "",
    "activate_date": moment().format("YYYY-MM-DD"),
    "status": "Y",
    "prices": [],
    "cust_price_id": ""
  })

  useEffect(() => {
    if (openModalPriceModel) {
      if (!isEmpty(getPriceModelData)) {
        let { cust_id, prices, logistic_type_id } = getPriceModelData
        getPriceSet(cust_id, prices, logistic_type_id)
        getZone(getPriceModelData.cust_id)
      }
      else {
        getPriceSet(defaultCustomer)
        getZone(defaultCustomer)
      }
    }
  }, [openModalPriceModel])


  useEffect(() => {
    if (openModalPriceModel && !isEmpty(getPriceModelData)) {
      setDataForm(getPriceModelData)
      if (getPriceModelData.admin_level1 !== "") onChangeProvince(getPriceModelData.admin_level1)
    }
    else
      setDataForm({
        "cust_id": defaultCustomer,
        "zone_id": "",
        "admin_level1": "",
        "admin_level2": "",
        "logistic_type_id": "",
        "product_id": "",
        "unit_id": "",
        "max_item": "",
        "min_item": "",
        "activate_date": moment().format("YYYY-MM-DD"),
        "status": "Y",
        "prices": [],
        "cust_price_id": ""
      })
  }, [getPriceModelData, openModalPriceModel])

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (dataForm.cust_id.length === 0 || dataForm.cust_id === "")
      validateCheck['cust_id'] = message
    if (dataForm.logistic_type_id.length === 0 || dataForm.logistic_type_id === "")
      validateCheck['logistic_type_id'] = message
    if (dataForm.status === "")
      validateCheck['status'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.cust_price_id,
          name: dataForm.cust_price_id === "" ? 'insert' : 'update'
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const getZone = (id_ref1 = "") => { // GET MASTER ZONE
    CommonService.getMasterData(auth, "zone", id_ref1)
      .then((res: any) => {
        let zone = res.result.zone.map((item) => ({
          label: item.zone_name, value: item.zone_id
        }))
        setMasterZone(zone)
      })
      .catch((err) => {
        setMasterZone([])
      })
  }

  const getPriceSet = (cust_id, prices = [], logistic_type_id = "") => {
    setLoading({ ...loading, visible: true, tip: "กำลังโหลด..." })
    PriceModelService.getPriceSet(auth, cust_id, logistic_type_id)
      .then((res: any) => {
        let { code, result, message, sys_message } = res
        if (code === 200) {
          let data = result.map((dt, idx) => {
            let priceDefault: any = prices.find((price: any) => price.price_set_id === dt.id)
            if (priceDefault)
              return { ...dt, no: (idx + 1), value: priceDefault.price_value }
            else
              return { ...dt, no: (idx + 1), value: "" }
          })
          priceSetListRef.current = data
          setPriceSetList(data)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const saveData = () => {
    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })

    let data = { ...dataForm }
    let prices = priceSetListRef.current.map((price) => ({
      "price_set_id": price.id,
      "price_value": price.value || ""
    }))
    data.prices = prices

    if (optionAlert.action.name === "insert") {
      PriceModelService.savePriceModel(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            setPriceModeStore({ openModalPriceModel: false, priceModelData: {} })
            onReload()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
    else {
      PriceModelService.updatePriceModel(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            setPriceModeStore({ openModalPriceModel: false, priceModelData: {} })
            onReload()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
  }

  const clearForm = () => {
    setValidation({})
  }

  return (<>
    <Modal
      title={{ text: `${isEmpty(getPriceModelData) ? 'เพิ่ม' : 'แก้ไข'}รายการ` }}
      open={openModalPriceModel}
      maskClosable={true}
      buttonOk={{ text: "ตกลง" }}
      shake={shakeIsWrong}
      width={"lg:w-3/4 sm:w-full"}
      onClosed={() => {
        setPriceModeStore({ openModalPriceModel: false, priceModelData: {} })
        clearForm()
      }}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className='md:space-y-2'>
          <div className="flex lg:flex-row md:flex-col">
            <div className="lg:w-3/5 md:w-full space-y-2 pr-6">
              <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
                <div className='flex-1'>
                  <Select
                    showSearch={true}
                    label="ลูกค้า"
                    required={true}
                    placeholder="ลูกค้า"
                    validate={validation?.cust_id}
                    disabled={isEmpty(getPriceModelData) ? false : true}
                    width={'100%'}
                    onChange={(select) => {
                      setDataForm({ ...dataForm, cust_id: select, zone_id: "" })
                      getPriceSet(select, [], dataForm.logistic_type_id)
                      getZone(select)
                    }}
                    defaultValue={dataForm.cust_id === "" ? [] : dataForm.cust_id}
                    options={masterOptions.customer}
                  />
                </div>

                <div className='flex-1'>
                  <Select
                    allowClear={true}
                    showSearch={true}
                    label="โซน"
                    placeholder="โซน"
                    onChange={(select) => setDataForm({ ...dataForm, zone_id: select })}
                    defaultValue={dataForm.zone_id === "" ? [] : dataForm.zone_id}
                    options={masterZone}
                  />
                </div>
              </div>

              <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
                <div className='flex-1'>
                  <Select
                    allowClear={true}
                    showSearch={true}
                    label="จังหวัด"
                    placeholder="จังหวัด"
                    onChange={(select) => {
                      if (select)
                        setDataForm({ ...dataForm, admin_level1: select, admin_level2: "" })
                      else
                        setDataForm({ ...dataForm, admin_level1: "", admin_level2: "" })

                      onChangeProvince(select)
                    }}
                    defaultValue={dataForm.admin_level1 === "" ? [] : dataForm.admin_level1}
                    options={masterOptions.province}
                  />
                </div>

                <div className='flex-1'>
                  <Select
                    allowClear={true}
                    showSearch={true}
                    label="อำเภอ"
                    placeholder="อำเภอ"
                    onChange={(select) => setDataForm({ ...dataForm, admin_level2: select })}
                    defaultValue={dataForm.admin_level2 === "" ? [] : dataForm.admin_level2}
                    options={masterOptions.distince}
                  />
                </div>
              </div>

              <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
                <div className='flex-1'>
                  <Select
                    showSearch={true}
                    label="ประเภทการขนส่ง"
                    required={true}
                    placeholder="ประเภทการขนส่ง"
                    validate={validation?.logistic_type_id}
                    disabled={isEmpty(getPriceModelData) ? false : true}
                    onChange={(select) => {
                      setDataForm({ ...dataForm, logistic_type_id: select })
                      getPriceSet(dataForm.cust_id, [], select)
                    }}
                    defaultValue={dataForm.logistic_type_id === "" ? [] : dataForm.logistic_type_id}
                    options={masterOptions.logistic_type}
                  />
                </div>

                <div className='flex-1'>
                  <Select
                    allowClear={true}
                    showSearch={true}
                    label="สินค้า"
                    placeholder="สินค้า"
                    onChange={(select) => setDataForm({ ...dataForm, product_id: select })}
                    defaultValue={dataForm.product_id === "" ? [] : dataForm.product_id}
                    options={masterOptions.product}
                  />
                </div>
              </div>

              <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
                <div className='flex-1'>
                  <InputNumber
                    label="จำนวนต่ำสุด"
                    placeholder="จำนวนต่ำสุด"
                    min={0}
                    defaultValue={dataForm.min_item === "" ? [] : dataForm.min_item}
                    onChange={(value) => setDataForm({ ...dataForm, min_item: value })}
                  />
                </div>

                <div className='flex-1'>
                  <InputNumber
                    label="จำนวนสูงสุด"
                    placeholder="จำนวนสูงสุด"
                    min={0}
                    defaultValue={dataForm.max_item === "" ? [] : dataForm.max_item}
                    onChange={(value) => setDataForm({ ...dataForm, max_item: value })}
                  />
                </div>
              </div>

              <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
                <div className='flex-1'>
                  <Select
                    allowClear={true}
                    showSearch={true}
                    label="หน่วยสินค้า"
                    placeholder="หน่วยสินค้า"
                    onChange={(select) => setDataForm({ ...dataForm, unit_id: select })}
                    defaultValue={dataForm.unit_id === "" ? [] : dataForm.unit_id}
                    options={masterOptions.product_unit}
                  />
                </div>

                <div className='flex-1'>
                  <DatePicker
                    allowClear={true}
                    placeholder="วันที่บังคับใช้งาน"
                    label="วันที่บังคับใช้งาน"
                    defaultValue={dataForm.activate_date === "" ? "" : dataForm.activate_date}
                    onChange={(d) => {
                      if (d)
                        setDataForm({ ...dataForm, activate_date: d.format("YYYY-MM-DD") })
                      else
                        setDataForm({ ...dataForm, activate_date: "" })
                    }}
                  />
                </div>
              </div>

              <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
                <div className='flex-1'>
                  <Select
                    showSearch={true}
                    label="สถานะ"
                    required={true}
                    placeholder="สถานะ"
                    validate={validation?.status}
                    onChange={(select) => setDataForm({ ...dataForm, status: select })}
                    defaultValue={dataForm.status === "" ? [] : dataForm.status}
                    options={masterOptions.common_status}
                  />
                </div>

                <div className='flex-1' />
              </div>
            </div>

            <div className="lg:w-2/5  md:w-full lg:pl-6 lg:-mt-4 md:pl-0 md:pt-2 border-l border-bold">
              <div>
                <div className="flex flex-row mb-1">
                  <div className="flex-1">
                    <label className={`flex flex-row text-sm font-medium text-gray-700 mt-2`}>
                      หมวดราคา
                    </label>
                  </div>
                </div>


                <div style={{ zoom: 0.9 }}>
                  <DataGrid
                    dataSource={priceSetList}
                    keyExpr="id"
                    allowColumnReordering={true}
                    columnAutoWidth={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    height={'378'}
                  >
                    <Scrolling columnRenderingMode="virtual" />
                    <Column
                      dataField="no"
                      caption="ลำดับ"
                      width={60}
                      alignment="center"
                      allowSorting={false}
                    />

                    <Column
                      dataField="price_set_name"
                      caption="ชื่อราคา"
                      alignment="center"
                      allowSorting={false} />

                    <Column
                      dataField="id"
                      caption="ราคา"
                      alignment="center"
                      width={100}
                      cellRender={(e) => {
                        let { id, value } = e.data
                        return <InputNumberAntd
                          min={0}
                          size="small"
                          defaultValue={value}
                          placeholder="ราคา"
                          style={{ width: "100%" }}
                          onChange={(number) => {
                            let mappingData = priceSetList.map((dt) => {
                              if (dt.id === id) dt.value = number
                              return dt
                            })
                            priceSetListRef.current = mappingData
                          }}
                        />
                      }}
                    />
                  </DataGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (action.name === "insert") saveData()
        if (action.name === "update") saveData()

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default ModalPriceModel