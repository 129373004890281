
import { useState, useRef, useEffect } from 'react'
import { createMultipleRef } from '../../../helpers'
import { ReportService } from "../../../services"
import { Input, Modal, Button, ModalAlert, Select, FormLoading } from '../../../components'
import DataGrid, { Paging, Column } from 'devextreme-react/data-grid';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { isEmpty, get } from 'lodash'
import moment from 'moment'
import { RefreshIcon, XIcon } from '@heroicons/react/solid';
import $ from 'jquery';

const { RangePicker } = DatePicker;
const format = 'HH:mm', dateFormat = 'YYYY-MM-DD';
let countdown: any = null
const ModalExportSetting = (props) => {
  let { masterOptions, open, data, auth } = props // STATES
  let { onClosed } = props // ACTIONS

  const reportIdRef: any = useRef(null);
  const isLoadingRef: any = useRef(false);
  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [filter, setFilter]: any = useState({
    sdate: moment().format("YYYY-MM-DD"),
    edate: moment().format("YYYY-MM-DD"),
    report_id: "",
    center_id: "",
    type: "report",
    ref_id1: "",
    status_list: []
  })

  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  useEffect(() => {
    if (open) {
      let defaultId = ""
      if (masterOptions.report.length > 0) {
        defaultId = masterOptions.report[0].value
        reportIdRef.current = defaultId
        setFilter({ ...filter, report_id: defaultId })
      }
      if (defaultId !== "") {
        loadDataExport(defaultId)
        setTimeout(() => { setAutoRefresh() }, 500);
      }
    }
    return () => clearInterval(countdown);

  }, [open, data])

  const setAutoRefresh = () => {
    let seconds = 15;
    countdown = setInterval(function () {
      $('#lb-countdown').text(seconds); // Update the element's text
      seconds--; // Decrease seconds

      if (seconds < 0) {
        clearInterval(countdown); // Stop the countdown

        loadDataExport(reportIdRef.current)
        setTimeout(() => {
          if (!isLoadingRef.current) setAutoRefresh()

        }, 1000);
      }
    }, 1000); // Update every second (1000 ms)
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    if (filter.report_id === "") {
      validateCheck['report_id'] = message
    }

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: null,
          name: "request"
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const loadDataExport = (report_id) => {
    setLoading({ ...loading, visible: true, tip: 'กำลังโหลด' })
    ReportService.getDataExport(auth, report_id)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setDataSource([])
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const saveData = () => {
    isLoadingRef.current = true
    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก' })

    let req_params = {
      func_name: `report_${filter.report_id}`,
      sdate: filter.sdate,
      edate: filter.edate,
      status_list: filter.status_list,
      export_type: 'excel',
      ref_id1: filter.ref_id1,
      center: filter.center_id
    }

    let data: any = {
      req_func: filter.report_id,
      req_params: JSON.stringify(req_params),
      export_type: "excel"
    }

    ReportService.saveRequestExport(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          loadDataExport(filter.report_id)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        isLoadingRef.current = false
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        isLoadingRef.current = false
        setLoading({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  return (<>
    <Modal
      title={{ text: `Export Setting` }}
      open={open}
      maskClosable={true}
      shake={shakeIsWrong}
      width={"w-3/4"}
      extra={<div className="flex flex-row space-x-2">
        <span className='text-sm  mt-1 text-gray-600'>
          {`Auto refresh in `}<span id="lb-countdown">15</span> {`sec.`}
        </span>
        <Button
          text="Refresh"
          style={{ marginLeft: -2 }}
          icon={<RefreshIcon className="w-4 h-4" />}
          type="white"
          onClick={() => loadDataExport(filter.report_id)}
        />
        <Button
          text="Closed"
          style={{ marginLeft: -2 }}
          icon={<XIcon className="w-4 h-4" />}
          type="white"
          onClick={() => onClosed()}
        />
      </div>}
      onClosed={() => onClosed()}
      footer={<></>}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div>
          <div className="flex flex-col mb-4 space-y-1">
            <div className="space-y-1">
              <div className="flex flex-row">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    รายงาน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      allowClear={false}
                      showSearch={true}
                      defaultValue={filter.report_id}
                      validate={validation.report_id}
                      placeholder="รายงาน"
                      options={masterOptions.report}
                      onChange={(value) => {
                        setFilter({ ...filter, report_id: value })
                        reportIdRef.current = value
                        loadDataExport(value)
                      }}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    วันที่ :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <RangePicker
                      allowClear={false}
                      defaultValue={[dayjs(filter.sdate, dateFormat), dayjs(filter.edate, dateFormat)]}
                      style={{ marginTop: 4, width: '100%' }}
                      onChange={(d: any) => {
                        if (d) {
                          setFilter({
                            ...filter,
                            sdate: d[0].format("YYYY-MM-DD"),
                            edate: d[1].format("YYYY-MM-DD")
                          })
                        }
                        else {
                          setFilter({
                            ...filter,
                            sdate: "",
                            edate: ""
                          })
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    หน่วยงาน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      allowClear={true}
                      showSearch={true}
                      defaultValue={filter.center_id}
                      placeholder="หน่วยงาน"
                      options={masterOptions.center}
                      onChange={(value) => setFilter({ ...filter, center_id: value })}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    เลขที่ใบสั่งงาน/แผนงาน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      label="เลขที่ใบสั่งงาน/แผนงาน"
                      placeholder="เลขที่ใบสั่งงาน/แผนงาน"
                      hiddenLabel={true}
                      onChange={(e) => setFilter({ ...filter, ref_id1: e.target.value })}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    สถานะ :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      allowClear={true}
                      showSearch={true}
                      multiple={true}
                      defaultValue={filter.status_list}
                      placeholder="สถานะ"
                      options={masterOptions.queue_status}
                      onChange={(value) => setFilter({ ...filter, status_list: value })}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  </label>
                  <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0 space-x-2">
                    <Button
                      text="Save Request"
                      type="primary"
                      style={{ marginLeft: -2 }}
                      onClick={() => onSave()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col -my-4 lg:space-y-0 sm:space-y-2">
            <div className="pt-2">
              <DataGrid
                ref={(ref) => gridRef.current = ref}
                dataSource={dataSource}
                // keyExpr="drop_id"
                showBorders={true}
                height={500}
              >
                <Paging enabled={false} />
                <Column
                  dataField="req_id"
                  caption="Request ID"
                  alignment="center"
                  allowEditing={false}
                  allowFiltering={false}
                  allowSorting={false}
                />

                <Column
                  dataField="req_date"
                  caption="Request Date"
                  alignment="center"
                  allowEditing={false}
                  allowSorting={false}
                />

                <Column
                  dataField="status_name"
                  caption="Request Status"
                  alignment="center"
                  allowEditing={false}
                  allowSorting={false}
                />

                <Column
                  dataField="finish_date"
                  caption="Finish Date"
                  alignment="center"
                  allowEditing={false}
                  allowSorting={false}
                />

                <Column
                  dataField="export_url"
                  caption="Download Url"
                  alignment="center"
                  allowSorting={false}
                  cellRender={(e) => {
                    let dt = e.data
                    if (isEmpty(dt.export_url))
                      return null
                    else
                      return <span
                        className="text-blue-500 hover:text-blue-400 cursor-pointer"
                        onClick={() => window.open(dt?.export_url)}>
                        Link
                      </span>
                  }} />
              </DataGrid>
            </div>
          </div>


        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (action.name === "request") saveData()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}

export default ModalExportSetting