import {
  ChartPieIcon,
  BellIcon,
  DocumentTextIcon,
  TableIcon,
  ShieldCheckIcon,
  LightBulbIcon,
  DocumentIcon
} from '@heroicons/react/outline'
import { ClipboardCheckIcon, ClipboardListIcon, CurrencyDollarIcon, UploadIcon } from '@heroicons/react/solid'

export const menuConfig: any = [
  { id: 100, name: 'แดชบอร์ด', path: '/dashboard', icon: ChartPieIcon, visible: false },
  { id: 200, name: 'อัปโหลดงาน', path: '/upload', icon: UploadIcon, visible: false },
  { id: 300, name: 'จัดการแผนงาน', path: '/plan', icon: ClipboardListIcon, visible: false },
  { id: 250, name: 'แนะนำเส้นทาง', path: '/route-suggestion', icon: LightBulbIcon, visible: false },
  {
    id: 450, name: 'ออกเอกสาร', path: '', icon: DocumentIcon, visible: false,
    subMenu: [
      {
        id: 451, name: 'ใบเบิก', path: '/requisition', visible: false
      }
    ]
  },
  { id: 400, name: 'ติดตามงาน', path: '/tracking', icon: ClipboardCheckIcon, visible: false },
  {
    id: 500, name: 'ค่าใช้จ่าย', path: '', icon: CurrencyDollarIcon, visible: false,
    subMenu: [
      {
        id: 501, name: 'บันทึกค่าใช้จ่าย', path: '/delivery-cost', visible: false
      },
      {
        id: 502, name: 'ข้อมูลการเติมน้ำมัน (Fleet Card)', path: '/fleet-card', visible: false
      },
      {
        id: 503, name: 'ราคาน้ำมัน', path: '/fuel-price', visible: false
      },
      {
        id: 504, name: 'กำหนดค่าขนส่ง', path: '/cost', visible: false
      }
    ]
  },
  { id: 600, name: 'ซ่อมบำรุง', path: '/maintanance', icon: CurrencyDollarIcon, visible: false },
  {
    id: 700, name: 'รายงาน', path: '/reports', icon: DocumentTextIcon, visible: false,
    subMenu: [
      {
        id: 701, name: 'รายงานทั่วไป', path: '/reports', visible: false
      },
      {
        id: 702, name: 'รายงานอุณหภูมิ', path: '/temperature', visible: false
      }
    ]
  },
  { id: 800, name: 'แจ้งเตือน', path: '/alert', icon: BellIcon, visible: false },
  {
    id: 900, name: 'ข้อมูลหลัก', path: '', icon: TableIcon, visible: false,
    subMenu: [
      {
        id: 901, name: 'ผู้รับจ้างช่วง', path: '/subcontract', visible: false
      },
      {
        id: 902, name: 'พนักงานขับรถ', path: '/driver', visible: false
      },
      {
        id: 903, name: 'ทะเบียนอุปกรณ์', path: '/device', visible: false
      },
      {
        id: 904, name: 'อู่รถ', path: '/Garage', visible: false
      },
      {
        id: 905, name: 'ประเภทการซ่อมบำรุง', path: '/maintenance-type', visible: false
      },
      {
        id: 906, name: 'ประเภทการซ่อมบำรุง (รายคัน)', path: '/maintenance-type-unit', visible: false
      },
      {
        id: 907, name: 'สินค้า', path: '/product', visible: false
      },
      {
        id: 908, name: 'ประเภทสินค้า', path: '/product-type', visible: false
      },
      {
        id: 909, name: 'กลุ่มสินค้า', path: '/product-group', visible: false
      },
      {
        id: 910, name: 'หน่วยสินค้า', path: '/product-unit', visible: false
      },
      {
        id: 911, name: 'รถ', path: '/vehicle', visible: false
      },
      {
        id: 912, name: 'ประเภทรถ', path: '/vehicle-type', visible: false
      },
      {
        id: 913, name: 'ประเภทเชื้อเพลิง', path: '/fuel-type', visible: false
      },
      {
        id: 914, name: 'ประเภทค่าใช้จ่าย', path: '/cost-type', visible: false
      },
      {
        id: 915, name: 'คลัง/สาขา', path: '/shippoint', visible: false
      },
      {
        id: 916, name: 'โซนการจัดส่ง', path: '/zone', visible: false
      },
      {
        id: 917, name: 'ลูกค้า', path: '/customer', visible: false
      },
      {
        id: 918, name: 'ประเภทงาน', path: '/job-type', visible: false
      },
      {
        id: 919, name: 'จัดส่งปลายทาง', path: '/ship-to', visible: false
      },
      {
        id: 920, name: 'ตั้งค่าภาพถ่าย', path: '/picture', visible: false
      },
      {
        id: 921, name: 'Customer User', path: '/customer-user', visible: false
      },
      {
        id: 922, name: 'ต้นเหตุ-สาเหตุ', path: '/cause-reason', visible: false
      },
      {
        id: 923, name: 'คะแนน', path: '/rating', visible: false
      },
      {
        id: 924, name: 'เหตุผลเพิ่มลดสินค้า', path: '/cause-of-product', visible: false
      },
      {
        id: 925, name: 'เอกสาร', path: '/documents', visible: false
      },
      {
        id: 927, name: 'ตั้งราคาค่าขนส่ง', path: '/price', visible: false
      },
      {
        id: 928, name: 'รายได้จากค่าขนส่ง', path: '/price-model', visible: false
      },
      {
        id: 929, name: 'ตารางวันหยุด', path: '/calendar', visible: false
      }
    ]
  },
  {
    id: 1000, name: 'ผู้ใช้งานและสิทธิ์', path: '', icon: ShieldCheckIcon, visible: false,
    subMenu: [
      {
        id: 1001, name: 'กลุ่มผู้ใช้งาน', path: '/user-group', visible: false
      },
      {
        id: 1002, name: 'ผู้ใช้งาน', path: '/users', visible: false
      },
      {
        id: 1003, name: 'กำหนดสิทธิ์', path: '/roles', visible: false
      },
      {
        id: 1004, name: 'ตรวจสอบ Log Mobile', path: '/mobile-log', visible: false
      }
    ]
  }
]