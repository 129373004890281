
import { useState, useRef, useEffect } from 'react'
import { createMultipleRef } from '../../helpers'
import { Input, Modal, ModalAlert, FormLoading, Select, DatePicker } from '../../components'
import { RequisitionService } from '../../services';
import { get, isEmpty } from "lodash";
const ModalDoc = (props) => {
  let { open, data, auth, masterOptions } = props // STATES
  let { onClosed } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [dataForm, setDataForm]: any = useState({
    "route_id": "",
    "route_no": "",
    "req_id": "",
    "req_date": ""
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  useEffect(() => {
    if (data) {
      setDataForm(data)
    }
  }, [open, data])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "route_id": "",
      "route_no": "",
      "req_id": "",
      "req_date": ""
    })
  }

  const genDoc = () => {
    let data = {
      "route_id": dataForm?.route_id,
      "req_by": dataForm?.req_id,
      "req_date": dataForm?.req_date
    }

    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    RequisitionService.genDoc(auth, data)
      .then((res: any) => {
        let { code, message, sys_message } = res.data

        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          initialData()
          onClosed(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false })
      })
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (dataForm.req_id === "")
      validateCheck['req_id'] = message
    if (dataForm.req_date === "")
      validateCheck['req_date'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.route_id,
          name: 'confirm_gen'
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const labelError = (fieldName) => {
    if (get(validation, fieldName)) {
      if (validation[fieldName].isError)
        return <p className="text-sm italic text-danger-600 ml-2">
          {validation[fieldName].message}
        </p>
      else
        return null
    }
  }

  return (<>
    <Modal
      title={{ text: `ออกใบเบิก` }}
      open={open}
      maskClosable={true}
      shake={shakeIsWrong}
      buttonOk={{ text: "ตกลง" }}
      onClosed={() => {
        onClosed()
        initialData()
      }}
      onOk={() => {
        onSave()
        // onClosed(true)
        // initialData()
      }}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className="flex flex-col space-y-3">
          <div className='flex-1'>
            <Input
              label="เลขที่แผนงาน"
              disabled={true}
              defaultValue={dataForm.route_no}
            />
          </div>

          <div className='flex-1'>
            <Select
              showSearch={true}
              label="ผู้เบิกสินค้า"
              required={true}
              placeholder="ผู้เบิกสินค้า"
              validate={validation?.req_id}
              onChange={(select) => setDataForm({ ...dataForm, req_id: select })}
              defaultValue={dataForm.req_id === "" ? [] : [dataForm.req_id]}
              options={masterOptions.user}
            />
          </div>

          <div className="flex-1">
            <div className="flex flex-col">
              <label className="flex flex-row text-sm font-medium text-gray-700 mb-1">
                วันที่เบิก<span className="text-red-500">* </span> {labelError("req_date")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <DatePicker
                  id="req_date"
                  placeholder="วันที่จัดส่ง"
                  allowClear={false}
                  hiddenLabel={true}
                  defaultValue={dataForm.req_date}
                  validate={validation['req_date']}
                  onChange={(d) => {
                    if (d)
                      setDataForm({ ...dataForm, req_date: d.format("YYYY-MM-DD") })
                    else
                      setDataForm({ ...dataForm, req_date: "" })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (action.name === "confirm_gen") genDoc()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}

export default ModalDoc