import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef, setOptions } from '../../helpers'
import { Input, Card, Button, ModalAlert, Select, FormLoading } from '../../components'
import { CommonService, RequisitionService } from "../../services";
import DataGrid, { SearchPanel, Scrolling, Item, Paging, Pager, Column, Toolbar, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import { RefreshIcon, SearchIcon, CheckIcon } from '@heroicons/react/outline';
import { DatePicker, Checkbox } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment'
import ModalDoc from './ModalDoc';
import { DocumentAddIcon, PencilAltIcon, XIcon } from '@heroicons/react/solid';
import { isEmpty, cloneDeep } from 'lodash';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let firstRender = false
const Requisition = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    user: []
  })
  const [loading, setLoading]: any = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [open, setOpen]: any = useState(false)
  const [openModalDoc, setOpenModalDoc]: any = useState(false)
  const [filterCurrent, setFilterCurrent]: any = useState({})
  const [selectRowKey, setSelectRowKey]: any = useState([])
  const [filter, setFilter]: any = useState({
    delivery_date: "",
    route_no: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [dataForm, setDataForm]: any = useState({
    "dlv_doc_no": "",
    "route_id": "",
    "ts_gate_in": "",
    "ts_gate_out": "",
    "mileage_start": "",
    "mileage_end": "",
    "service_info": "",
    "tasks": [],
    "cost_items": []
  })

  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)
  let columns = [
    {
      dataField: 'no',
      caption: "ลำดับ",
      width: 90,
      alignment: "center",
      allowFiltering: false
    },
    {
      dataField: 'route_no',
      caption: "เลขที่แผนงาน",
      allowFiltering: false
    },
    {
      dataField: 'vehicle_name',
      caption: "ทะเบียน",
      allowFiltering: false
    },
    {
      dataField: 'service_name',
      caption: "พนักงาน",
      allowFiltering: false
    },
    {
      dataField: 'job_type_name',
      caption: "ประเภทงาน"
    },
    {
      dataField: 'req_by_name',
      caption: "ผู้เบิกสินค้า",
      allowFiltering: false
    },
    {
      dataField: 'req_no',
      caption: "เลขที่เอกสาร",
      allowFiltering: false
    },
    {
      dataField: 'req_date',
      caption: "วันที่เบิกสินค้า",
      allowFiltering: false
    },
    {
      dataField: 'status_name',
      caption: "สถานะ"
    }
  ]


  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "user")
        .then((res: any) => {
          let user = res.result.user.map((item) => ({ label: item.name, value: item.id }))
          setMasterOptions({
            ...masterOptions,
            user
          })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
      searchRequisition()
    }
    else
      firstRender = true
  }, [getAccountAuth])

  //#region 

  const searchRequisition = (isReloadCurrent = false, reloadByRouteID = "") => {
    setLoading(true)
    setFilterCurrent(filter)
    let data = isReloadCurrent ? filterCurrent : filter
    RequisitionService.getRequisition(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion

  const deleteRequisition = (id, req_by, req_date) => {
    RequisitionService.deleteRequisition(auth, {
      route_id: id,
      req_by,
      req_date
    })
      .then((res: any) => {
        if (res.code === 200) {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            title: "ลบข้อมูลสำเร็จ"
          }))
          searchRequisition(true)
        }
      })
      .catch((err) => {
        setOptionAlert(setOptions({
          defaultOption: optionAlert,
          show: true,
          type: "danger",
          title: "ผิดพลาด",
          description: { visible: true, content: `${err}` }
        }))
      })
  }

  const handleChecked = (route_id, checked) => {
    let _selectRowKey = cloneDeep(selectRowKey)

    if (checked) {
      _selectRowKey.push(route_id)
    }
    else {
      _selectRowKey = _selectRowKey.filter(id => id !== route_id);
    }
    setSelectRowKey(_selectRowKey)
  }


  return (
    <div className="space-y-3 p-4">
      <Card title="เอกสารใบเบิก">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading} >
            <div className="flex flex-row mb-2">
              <div className="flex flex-row flex-wrap space-x-2">
                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    วันที่จัดส่ง :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <DatePicker
                      allowClear={false}
                      placeholder="วันที่จัดส่ง"
                      defaultValue={filter.delivery_date}
                      onChange={(d) => {
                        if (d) {
                          setFilter({ ...filter, delivery_date: d.format("YYYY-MM-DD") })
                        }
                        else {
                          setFilter({ ...filter, delivery_date: "" })
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    เลขที่แผนงาน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      label="เลขที่แผนงาน"
                      placeholder="เลขที่แผนงาน"
                      width="w-52"
                      hiddenLabel={true}
                      onChange={(e) => setFilter({ ...filter, route_no: e.target.value })}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                    <button
                      type="button"
                      title="ค้นหา"
                      className={`flex flex-row ${loading && 'cursor-not-allowed'} items-center rounded border border-transparent bg-blue-100 mt-0.5 px-2.5 py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200`}
                      onClick={() => {
                        if (!loading) {
                          searchRequisition()
                        }
                      }}
                    >
                      <div className="flex flex-row-reverse">
                        <span>ค้นหา</span>
                        <SearchIcon className="w-4 h-4 mr-1" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={dataSource}
              keyExpr={'route_id'}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
            // onSelectionChanged={(e) => setSelectRowKey(e.selectedRowKeys)}
            >
              <Scrolling columnRenderingMode="virtual" />
              {/* <Selection
                mode="multiple"
                selectAllMode="allPages"
                showCheckBoxesMode="always"
              /> */}
              <SearchPanel visible={false} />
              <Paging defaultPageSize={10} />
              <Pager
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
              />
              <HeaderFilter visible={true} />
              <Column
                // fixed={true}
                // fixedPosition="right"
                allowFiltering={false}
                dataField="status"
                caption=""
                alignment="center"
                // width={90}
                cellRender={(e) => {
                  let { route_id } = e.data
                  if (e.value === "") {
                    return <Checkbox defaultChecked={false} disabled />
                  }
                  else if (e.data.is_approved === "Y") {
                    return <div className='text-center ml-2'>
                      <CheckIcon className="w-5 h-5 text-success-500" />
                    </div>
                  }
                  else {
                    return <Checkbox onChange={(e) => handleChecked(route_id, e.target.checked)} />
                  }

                }}
              />

              {
                columns.map((column) => <Column
                  key={column.dataField}
                  width={column.width}
                  dataField={column.dataField}
                  caption={column.caption}
                  alignment={column.alignment}
                  allowFiltering={column.allowFiltering}
                />)
              }

              <Column
                fixed={true}
                fixedPosition="right"
                allowFiltering={false}
                dataField="doc_url"
                caption="ใบเบิก"
                alignment="center"
                width={100}
                cellRender={(e) => {
                  e.cancel = "true"
                  return e.value === "" ? <Button
                    text="ออกใบเบิก"
                    size="sm"
                    type="warning"
                    width="w-20"
                    iconPosition='start'
                    onClick={() => {
                      e.cancel = "true"
                      let data = e.data
                      setOpenModalDoc(true)
                      setDataForm(data)
                    }}
                  /> :
                    <a href={e.value} target="_blank">
                      <Button
                        text="พิมพ์"
                        size="sm"
                        type="success"
                        width="w-20"
                        iconPosition='start'
                        onClick={() => {
                          e.cancel = "true"
                          return
                        }}
                      />
                    </a>
                }}
              />

              <Column
                fixed={true}
                fixedPosition="right"
                allowFiltering={false}
                dataField="doc_url"
                caption="ใบเบิก"
                alignment="center"
                width={100}
                cellRender={(e) => {
                  e.cancel = "true"
                  let data = e.data
                  return e.value !== "" ?
                    <Button
                      text="ยกเลิก"
                      size="sm"
                      type="danger"
                      width="w-20"
                      iconPosition='start'
                      onClick={() => {
                        setOptionAlert({
                          ...optionAlert,
                          action: {
                            id: data?.route_id,
                            req_by: data?.req_id,
                            req_date: data?.req_date,
                            name: 'delete'
                          },
                          type: "confirm",
                          title: "ยืนยัน",
                          show: true,
                          description: {
                            visible: true,
                            type: "text",
                            content: "คุณแน่ใจที่จะลบข้อมูล ?",
                            contentLists: []
                          }
                        })
                      }}
                    />
                    : null
                }}
              />

              <Toolbar visible={false}>
                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Refresh"
                    onClick={() => { }}
                  >
                    <RefreshIcon className="w-4 h-4" />
                  </button>
                </Item>

                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Export"
                  >
                    <img className="h-4 w-4" src="/Images/excel.png" />
                  </button>
                </Item>

                <Item location="after">
                  <Input
                    icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                    placeholder="ค้นหา..."
                    onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
                  />
                </Item>
              </Toolbar>
            </DataGrid>
          </FormLoading>
        </div>

        <ModalDoc
          auth={auth}
          open={openModalDoc}
          data={dataForm}
          reloadData={(route_id) => searchRequisition(true, route_id)}
          onClosed={(reload) => {
            setOpenModalDoc(false)
            reload && searchRequisition(true)
          }}
          masterOptions={masterOptions}
        />
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { type, action } = optionAlert
          if (action.name === "delete") {
            deleteRequisition(action.id, action.req_by, action.req_date)
          }
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default Requisition